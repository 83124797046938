import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout.jsx';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { KDLogo, backgroundColorHeader } from './klinika-digjitale.module.scss'
import { motion } from 'framer-motion';
import Button from "@mui/material/Button";
import SEO from "../../components/seo"

const hceLink = "https://klinikadigjitale.net/en/"
export const query = graphql`
  query LocalesKD($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function KlinikaDigjitale() {
    const translate = useTranslation().t;
    return (
        <Layout>
          <SEO title="Klinika Digjitale"/>
           <div className={backgroundColorHeader}>
           <svg className={KDLogo} xmlns="http://www.w3.org/2000/svg" width="440" height="150" viewBox="0 0 504.304 113.8">
                    <path id="Path_1" data-name="Path 1" d="M56.9,7.8a49,49,0,1,1-49,49,49,49,0,0,1,49-49M47,55.8c-3.9,2.7-5.6,5.4-8.9,5.3H26c.4,2.8,10.2,13.7,13.5,16.5,1.3,1.2,2.5,2.3,4,3.5,2.2,1.8,11.9,9.2,13.5,9.1,1.1-.1,8.1-5,9.2-5.8,1.9-1.4,8.7-6.9,10-8.7.7-.8,1.2-1.1,2-1.9,2.7-2.7,8-9.3,9.7-12.7l-10.3.1c-6.5,0-4.7-.3-9.3-8.2-.5-.9-1.5-3.2-2.3-3.8-1.2,3.5-2.4,7.1-3.6,10.6-.6,1.7-3.1,9.9-3.7,10.7a2.55,2.55,0,0,1-4.4-.5c-.9-1.6-1.6-3.1-2.4-4.8C51.2,63.8,47.7,56.4,47,55.8ZM62.2,28.9A21.42,21.42,0,0,0,57,32.8a21.79,21.79,0,0,1-2.6-2.1,20,20,0,0,0-18.9-2.8c-17.9,6.4-14,27.5-11.8,28.2H36.9c1.7,0,1.6-.1,2.6-.9L45,50.9c1-.8,2.6-2.4,4.4-1.3,1.2.8,6.3,11.3,6.9,12.7.9-1,3.2-9,3.8-10.9s2.9-9.7,3.8-10.6C65,39.7,67,39.5,68.3,42c.8,1.5,1.8,3.1,2.6,4.7s1.7,3.1,2.6,4.7c.8,1.4,1.7,3.4,2.6,4.7H90.5c1.5-2.4,2-7.6,1.7-10.9a20.49,20.49,0,0,0-9.7-15.3C76.3,26.2,68.5,25.6,62.2,28.9ZM56.9,4.8A52.07,52.07,0,1,1,20.051,20.051,52.07,52.07,0,0,1,56.9,4.8Zm0,109A56.9,56.9,0,1,0,0,56.9a56.9,56.9,0,0,0,56.9,56.9Z" fill="#0190b0" fill-rule="evenodd"/>
                    <path id="Path_2" data-name="Path 2" d="M122,75V39.2h8.3V54.6l13.1-15.4h9.3l-13.4,16L153.6,75h-9.4L134.1,60.6l-3.7,3.9V75Z" fill="#0190b0"/>
                    <path id="Path_3" data-name="Path 3" d="M155.5,38.2h8.1V65.6c0,2,.9,3,2.6,3a6.88,6.88,0,0,0,1.3-.2,5,5,0,0,0,1.3-.5l1.1,6a18.8,18.8,0,0,1-7.2,1.5,6.45,6.45,0,0,1-7.2-7V38.2Z" fill="#0190b0"/>
                    <path id="Path_4" data-name="Path 4" d="M172.5,45.5V38.2h8.1v7.3Zm0,29.5V48.6h8.1V75.1h-8.1Z" fill="#0190b0"/>
                    <path id="Path_5" data-name="Path 5" d="M211.6,75h-8.1V60.1a5.76,5.76,0,0,0-1-3.8,3.22,3.22,0,0,0-2.6-1.2,3.53,3.53,0,0,0-1.7.4,7.54,7.54,0,0,0-1.7,1,6.73,6.73,0,0,0-1.4,1.5,11.4,11.4,0,0,0-1,1.9V75.1H186V48.6h7.3V53a10.47,10.47,0,0,1,4.1-3.7,13.34,13.34,0,0,1,6.1-1.3,8,8,0,0,1,4.1.9,6.89,6.89,0,0,1,2.4,2.4,9.19,9.19,0,0,1,1.1,3.3,20.4,20.4,0,0,1,.3,3.4V75Z" fill="#0190b0"/>
                    <path id="Path_6" data-name="Path 6" d="M216.9,45.5V38.2H225v7.3Zm0,29.5V48.6H225V75.1h-8.1Z" fill="#0190b0"/>
                    <path id="Path_7" data-name="Path 7" d="M248.3,75l-6.9-10.6-2.8,2.9V75h-8.1V38.2h8.1V59.5l8.9-11h8.6l-9.5,11.4L256.9,75Z" fill="#0190b0"/>
                    <path id="Path_8" data-name="Path 8" d="M266.4,75.5a11.48,11.48,0,0,1-3.7-.6,7.89,7.89,0,0,1-2.9-1.8,8.77,8.77,0,0,1-1.9-2.7,7.39,7.39,0,0,1-.7-3.4,8.63,8.63,0,0,1,.8-3.6,8.86,8.86,0,0,1,2.3-2.8,10.15,10.15,0,0,1,3.6-1.8,16.13,16.13,0,0,1,7.8-.4,17.31,17.31,0,0,1,2.7.8V58c0-3.1-1.8-4.6-5.3-4.6a11.41,11.41,0,0,0-4.1.7,18.372,18.372,0,0,0-4.1,2.2l-2.4-5a24.33,24.33,0,0,1,5.4-2.6,19.81,19.81,0,0,1,6-.9c4,0,7.1.9,9.3,2.8s3.3,4.6,3.3,8.3v6.8a2.5,2.5,0,0,0,.4,1.7,2.26,2.26,0,0,0,1.3.6v6.8a6.8,6.8,0,0,1-1.9.3c-.6,0-1.1.1-1.6.1a5.31,5.31,0,0,1-3.4-.9,3.81,3.81,0,0,1-1.5-2.5l-.2-1.2a12.7,12.7,0,0,1-4.2,3.5,8.8,8.8,0,0,1-5,1.4Zm2.3-5.8a7,7,0,0,0,2.5-.5,5.61,5.61,0,0,0,2-1.2,2.42,2.42,0,0,0,1.2-1.9V63.6a8.65,8.65,0,0,0-2.3-.6,9.34,9.34,0,0,0-2.3-.2,6.2,6.2,0,0,0-3.7,1,2.91,2.91,0,0,0-1.4,2.6,3,3,0,0,0,1.1,2.4,5,5,0,0,0,2.9.9Z" fill="#0190b0"/>
                    <path id="Path_9" data-name="Path 9" d="M300.8,75V39.2h13.4a20.87,20.87,0,0,1,7.8,1.4,15.88,15.88,0,0,1,5.6,3.8,16.329,16.329,0,0,1,3.4,5.7,20,20,0,0,1,1.1,6.9,19.5,19.5,0,0,1-1.3,7.4,14.24,14.24,0,0,1-3.6,5.6,15,15,0,0,1-5.6,3.6,19,19,0,0,1-7.3,1.3H300.8Zm22.8-17.9a14.42,14.42,0,0,0-.6-4.3,10.081,10.081,0,0,0-1.8-3.4,8.35,8.35,0,0,0-3-2.2,10.19,10.19,0,0,0-4-.8h-5.1V67.8h5.1a9.83,9.83,0,0,0,4-.8,7.22,7.22,0,0,0,2.9-2.2,11,11,0,0,0,1.8-3.4,14.889,14.889,0,0,0,.7-4.3Z" fill="#00a5cb"/>
                    <path id="Path_10" data-name="Path 10" d="M336.2,45.5V38.2h8.1v7.3Zm0,29.5V48.6h8.1V75.1h-8.1Z" fill="#00a5cb"/>
                    <path id="Path_11" data-name="Path 11" d="M360.1,75.1a11.25,11.25,0,0,1-4.8-1.1,11.63,11.63,0,0,1-3.7-2.9,14.391,14.391,0,0,1-2.4-4.2,14.56,14.56,0,0,1-.9-5.2,15.69,15.69,0,0,1,.9-5.4,12.18,12.18,0,0,1,2.5-4.3,12.42,12.42,0,0,1,3.9-2.9,11.81,11.81,0,0,1,5-1.1,10.17,10.17,0,0,1,5.3,1.3,9.57,9.57,0,0,1,3.6,3.6V48.6h7V73.2a13.349,13.349,0,0,1-1.1,5.4,10.65,10.65,0,0,1-3.2,4.1,14.772,14.772,0,0,1-4.8,2.6,20.18,20.18,0,0,1-6.2.9,17.59,17.59,0,0,1-7.3-1.4,15.52,15.52,0,0,1-5.3-3.9l4.3-4.4a11.2,11.2,0,0,0,8.2,3.6,8.63,8.63,0,0,0,2.8-.4,8.36,8.36,0,0,0,2.3-1.2,6,6,0,0,0,1.6-2.2,7.94,7.94,0,0,0,.6-3.2V70.5a8.2,8.2,0,0,1-3.5,3.4,9.5,9.5,0,0,1-4.8,1.2Zm2.8-6.4a5.38,5.38,0,0,0,3.3-1,8.15,8.15,0,0,0,2.3-2.6V59.6a7.76,7.76,0,0,0-2.5-3.4,5.92,5.92,0,0,0-3.5-1.2,4.75,4.75,0,0,0-2.4.6,7,7,0,0,0-1.9,1.6,8.359,8.359,0,0,0-1.2,2.3,7.83,7.83,0,0,0-.4,2.6,7.63,7.63,0,0,0,.5,2.7,7.43,7.43,0,0,0,1.3,2.1,5.58,5.58,0,0,0,2,1.4,9.35,9.35,0,0,0,2.5.4Z" fill="#00a5cb"/>
                    <path id="Path_12" data-name="Path 12" d="M382.2,85.2a12.09,12.09,0,0,1-3.8-.6,10.911,10.911,0,0,1-3.3-1.9l3.4-5.5a3.53,3.53,0,0,0,1.3.8,4,4,0,0,0,1.5.3,2.56,2.56,0,0,0,1.9-.8,2.79,2.79,0,0,0,.8-2V48.6h8.1V74.9a11.579,11.579,0,0,1-.7,4.2,10.771,10.771,0,0,1-2,3.3,9.451,9.451,0,0,1-3.1,2.1,14.7,14.7,0,0,1-4.1.7Zm1.7-39.7V38.2H392v7.3Z" fill="#00a5cb"/>
                    <path id="Path_13" data-name="Path 13" d="M397.4,45.5V38.2h8.1v7.3Zm0,29.5V48.6h8.1V75.1h-8.1Z" fill="#00a5cb"/>
                    <path id="Path_14" data-name="Path 14" d="M428.1,73.6a28,28,0,0,1-3.8,1.3,15.93,15.93,0,0,1-4.3.6,8.25,8.25,0,0,1-2.8-.4,7.14,7.14,0,0,1-2.3-1.2,6,6,0,0,1-1.6-2.2,8.111,8.111,0,0,1-.6-3.3V54.7h-3.4V48.6h3.4V40.2h8.1v8.4h5.4v6.1h-5.4v11a2.23,2.23,0,0,0,.7,1.9,2.35,2.35,0,0,0,1.7.6,6.8,6.8,0,0,0,1.9-.3,6.69,6.69,0,0,0,1.7-.7Z" fill="#00a5cb"/>
                    <path id="Path_15" data-name="Path 15" d="M438.8,75.5a11.48,11.48,0,0,1-3.7-.6,7.89,7.89,0,0,1-2.9-1.8,8.77,8.77,0,0,1-1.9-2.7,7.389,7.389,0,0,1-.7-3.4,8.631,8.631,0,0,1,.8-3.6,8.86,8.86,0,0,1,2.3-2.8,10.15,10.15,0,0,1,3.6-1.8,16.13,16.13,0,0,1,7.8-.4,17.311,17.311,0,0,1,2.7.8V58c0-3.1-1.8-4.6-5.3-4.6a11.41,11.41,0,0,0-4.1.7,18.37,18.37,0,0,0-4.1,2.2l-2.4-5a24.329,24.329,0,0,1,5.4-2.6,19.81,19.81,0,0,1,6-.9c4,0,7.1.9,9.3,2.8s3.3,4.6,3.3,8.3v6.8a2.5,2.5,0,0,0,.4,1.7,2.26,2.26,0,0,0,1.3.6v6.8a6.8,6.8,0,0,1-1.9.3c-.6,0-1.1.1-1.6.1a5.31,5.31,0,0,1-3.4-.9,3.81,3.81,0,0,1-1.5-2.5l-.2-1.2a12.7,12.7,0,0,1-4.2,3.5,8.8,8.8,0,0,1-5,1.4Zm2.4-5.8a7,7,0,0,0,2.5-.5,5.61,5.61,0,0,0,2-1.2,2.42,2.42,0,0,0,1.2-1.9V63.6a8.65,8.65,0,0,0-2.3-.6,9.34,9.34,0,0,0-2.3-.2,6.2,6.2,0,0,0-3.7,1,2.91,2.91,0,0,0-1.4,2.6,3,3,0,0,0,1.1,2.4A4.56,4.56,0,0,0,441.2,69.7Z" fill="#00a5cb"/>
                    <path id="Path_16" data-name="Path 16" d="M460.7,38.2h8.1V65.6c0,2,.9,3,2.6,3a6.88,6.88,0,0,0,1.3-.2,5,5,0,0,0,1.3-.5l1.1,6a18.8,18.8,0,0,1-7.2,1.5,6.45,6.45,0,0,1-7.2-7V38.2Z" fill="#00a5cb"/>
                    <path id="Path_17" data-name="Path 17" d="M490.3,75.5a16.43,16.43,0,0,1-6-1.1,12.94,12.94,0,0,1-4.5-2.9,12.31,12.31,0,0,1-2.8-4.3,14.469,14.469,0,0,1-1-5.1,14,14,0,0,1,1-5.4,13.131,13.131,0,0,1,2.8-4.5,11.87,11.87,0,0,1,4.5-3,16.43,16.43,0,0,1,6-1.1,16,16,0,0,1,6,1.1,12.3,12.3,0,0,1,4.4,3,12.46,12.46,0,0,1,2.7,4.4,15,15,0,0,1,.9,5.2,6.6,6.6,0,0,1-.1,1.4,4.3,4.3,0,0,1-.2,1.2H484.4a5.69,5.69,0,0,0,1.9,4.1,6.49,6.49,0,0,0,4,1.4,6.92,6.92,0,0,0,3.4-.9,4.45,4.45,0,0,0,2.2-2.3l6.9,1.9a12.37,12.37,0,0,1-4.8,5.1,14.87,14.87,0,0,1-7.7,1.8Zm5.6-16.2a6.48,6.48,0,0,0-1.8-4,5.82,5.82,0,0,0-7.8,0,6.48,6.48,0,0,0-1.8,4Z" fill="#00a5cb"/>
           </svg>
           </div>
            <div class="inner" style={{ width: "90%", marginTop: "3rem", paddingBottom: "1rem"}}>
                <Typography sx={{ marginTop: "1rem", marginBottom: "3rem", marginLeft: "auto", marginRight: "auto", whiteSpace: "pre-line" }} >
                    {translate("kd-description")}
                </Typography>
                <motion.button
                            style={{ border: "none", margin: "auto", display: "flex" }}
                            whileHover={{
                                scale: 1.1,
                            }}
                            whileTap={{ scale: 0.9 }}
                        >
                            {
                                    <Button href={hceLink} target="_blank" size="large" variant="contained" color="secondary" >
                                        <Typography align="center" color="primary" variant="button">
                                        {translate("visit-website")}
                                        </Typography>
                                    </Button>
                            }
                        </motion.button>
            </div>
        </Layout>
  )
}
